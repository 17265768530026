import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GoLinkExternal } from 'react-icons/go'
import Collapsible from 'react-collapsible'

const Landing = props => {
  const { t, i18n } = useTranslation()

  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: i18n.language,
        }}
      >
        <title>Levo {t('beta')}</title>
        <meta name="description" content={t('betaWelcome')} />
      </Helmet>

      <BannerLanding title={t('betaWelcome')} subText={t('betaInstructions')} />

      <div id="main">
        <section id="one">
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 30,
                paddingBottom: 30,
              }}
            >
              <h2 style={{ textAlign: 'center' }}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.levoapp.levo"
                  target="blank"
                  rel="external"
                >
                  Android <GoLinkExternal />
                </a>
              </h2>
            </div>
            <div
              style={{
                flex: 1,
                justifyContent: 'center',
                paddingTop: 30,
                paddingBottom: 30,
              }}
            >
              <h2 style={{ textAlign: 'center' }}>
                <a
                  href="https://testflight.apple.com/join/5hx4o31Y"
                  target="blank"
                  rel="external"
                >
                  iOS <GoLinkExternal />
                </a>
              </h2>
            </div>
          </div>
        </section>
        <section id="two">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 30,
              paddingBottom: 200,
            }}
          >
            <div style={{ flex: 1 }}>
              <h2>{t('faq.title')}</h2>
            </div>
            <div
              style={{
                flex: 1,
                borderWidth: 5,
                borderColor: 'red',
                width: '60%',
              }}
            >
              <Collapsible
                triggerDisabled
                open
                trigger={t('faq.transferProgressTitle')}
                triggerStyle={{ fontWeight: 'bold' }}
                style={{
                  borderWidth: 5,
                  borderColor: 'red',
                  backgroundColor: 'red',
                }}
              >
                <p>{t('faq.transferProgressBody')}</p>
              </Collapsible>
              <Collapsible
                triggerDisabled
                open
                trigger={t('faq.androidInstallTitle')}
                triggerStyle={{ fontWeight: 'bold' }}
                style={{
                  borderWidth: 5,
                  borderColor: 'red',
                  width: '80%',
                  backgroundColor: 'red',
                }}
              >
                <p>
                  {t('faq.androidInstallBody')}
                  <a
                    href="https://forms.gle/Y69VUbAh3uEdLMFi6"
                    target="blank"
                    rel="external"
                  >
                    {t('feedbackForm')} <GoLinkExternal />
                  </a>
                </p>
              </Collapsible>
              <Collapsible
                triggerDisabled
                open
                trigger={t('faq.iosInstallTitle')}
                triggerStyle={{ fontWeight: 'bold' }}
                style={{
                  borderWidth: 5,
                  borderColor: 'red',
                  width: '80%',
                  backgroundColor: 'red',
                }}
              >
                <p>
                  {t('faq.iosInstallBody')}
                  <a
                    href="https://forms.gle/Y69VUbAh3uEdLMFi6"
                    target="blank"
                    rel="external"
                  >
                    {t('feedbackForm')} <GoLinkExternal />
                  </a>
                </p>
              </Collapsible>
              <Collapsible
                triggerDisabled
                open
                trigger={t('faq.feedbackTitle')}
                triggerStyle={{ fontWeight: 'bold' }}
                style={{
                  borderWidth: 5,
                  borderColor: 'red',
                  width: '80%',
                  backgroundColor: 'red',
                }}
              >
                <p>
                  {t('faq.feedbackBody')}{' '}
                  <a
                    href="https://forms.gle/Y69VUbAh3uEdLMFi6"
                    target="blank"
                    rel="external"
                  >
                    {t('feedbackForm')} <GoLinkExternal />
                  </a>
                </p>
              </Collapsible>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Landing
